import React from "react";
import PropTypes from "prop-types";

import { graphql, Link } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import Seo from "../components/Seo";

import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";
import { kebabCase } from "lodash";
import FullWidthImage from "../components/FullWidthImage";
import DisplayContentHeader from "../components/DisplayContentHeader";
import AuthorSummary from "../components/AuthorSummary";
import Summary from "../components/Summary";
import DisplayGallery from "../components/DisplayGallery";
import { DiscussionEmbed } from "disqus-react";
import { nanoid } from "nanoid";
const pluralize = require("pluralize");
function returnAuthorSlugs(authorCollection) {
  let k = null;
  let v = null;
  let results = [];

  authorCollection.forEach((author) => {
    k = author.frontmatter.name;
    v = author.fields.slug;
    results[k] = v;
  });
  return results;
}

// eslint-disable-next-line
export const BlogGalleryPageTemplate = ({
  slug,
  title,
  subtitle,
  footer,
  images,
  author,
  creationDate,
  breadcrumbs,
  options,
  body,
  helmet,
  authors,
  config,
  photos,
  summary,
  category,
  tags,
  layout,
  videos,
  related,
  header,
}) => {
  let templateName = "blog-gallery-post",
    heroVideo,
    heroImage,
    smallImage,
    icon,
    footerImage;

  if (!layout) layout = [];

  if (images) {
    if (images.hero) {
      heroImage = getImage(images.hero) || images.hero;
    }
    if (images.footer) {
      footerImage = getImage(images.footer) || images.footer;
    }
    if (images.smallImage) smallImage = images.smallImage;
    if (images.icon) icon = images.smallImage;
  }

  if (videos && videos.hero) heroVideo = videos.hero;
  if (!layout.position) layout.position = "below";

  const showTitle = options.showTitle;
  const showTitlesSwapped = options.showTitlesSwapped;

  let showDisplayContentHeader = true;
  if (
    !options.showDisplayContentHeader &&
    options.showDisplayContentHeader !== undefined &&
    options.showDisplayContentHeader !== null
  ) {
    showDisplayContentHeader = false;
  }

  const PageContent = HTMLContent || Content;

  let showSummary = options.showSummary;
  let showTags = options.showTags;
  let showComments = options.showComments;
  let showAuthorSummary = options.showAuthorSummary;
  let showPrevNext = options.showPrevNext;

  let showRelated = options.showRelated;
  let showPopular = options.showPopular;

  config = config.frontmatter;

  if (config.overrides.showTitle.enabled) {
    showTitle = config.overrides.showTitle.value;
  }
  if (config.overrides.showSummary.enabled) {
    showSummary = config.overrides.showSummary.value;
  }
  if (config.overrides.showTags.enabled) {
    showTags = config.overrides.showTags.value;
  }
  if (config.overrides.showComments.enabled) {
    showComments = config.overrides.showComments.value;
  }
  if (config.overrides.showAuthorSummary.enabled) {
    showAuthorSummary = config.overrides.showAuthorSummary.value;
  }
  if (config.overrides.showPrevNext.enabled) {
    showPrevNext = config.overrides.showPrevNext.value;
  }

  authors = authors.nodes;

  if (!process.env.GATSBY_DISQUS_NAME) showComments = false;
  let disqusConfig;
  if (showComments) {
    disqusConfig = {
      shortname: process.env.GATSBY_DISQUS_NAME,
      config: { identifier: slug, title },
    };
  }
  let authorSlugs = returnAuthorSlugs(authors);
  let authorSlug = authorSlugs[author];
  let grandparent, grandparentSlug, backText;

  if (slug.indexOf("/blog") !== -1) {
    grandparent = "Blog";
    grandparentSlug = "/blog/";
    backText = " Original Blog Entry";
  } else {
    grandparent = "Videos";
    grandparentSlug = "/videos/";
    backText = " Original Video";
  }

  return (
    <div>
      {helmet || null}

      {heroImage ? (
        <FullWidthImage
          img={heroImage}
          title={title}
          subheading="Photo Gallery"
          imgPosition="center"
          showTitle={showTitle}
        />
      ) : null}

      <section className="section section--gradient">
        <div className="container">
          <div className="content">
            <div className="">
              <DisplayContentHeader
                title={`${title} Photo Gallery`}
                grandparent={grandparent}
                grandparentSlug={grandparentSlug}
                parent={title}
                parentSlug={slug}
                relatedPerson={author}
                relatedPersonType="Author"
                relatedPersonSlug={authorSlug}
                date={creationDate}
              />
            </div>
            {showSummary && summary ? (
              <div className="content">
                <Summary
                  summary={summary}
                  image={images.smallImage}
                  alt={title}
                  category={category}
                />
              </div>
            ) : null}
            <div className="content">
              <DisplayContentHeader title={`Photos`} />
              {photos &&
              photos.showGallery &&
              photos.photo &&
              photos.photo.length ? (
                <>
                  <DisplayGallery gallery={photos.photo} sort={true} />
                </>
              ) : (
                <>
                  <p>
                    We have no gallery images for this article at this time.
                  </p>
                </>
              )}
              {showTags && tags && tags.length ? (
                <div style={{ marginTop: `2rem` }}>
                  <h4>Tags:</h4>
                  <ul className="taglist">
                    {tags.map((tag) => (
                      <li key={nanoid()}>
                        <Link to={`/tag/${kebabCase(tag)}/`}>{tag}</Link>
                      </li>
                    ))}
                  </ul>
                </div>
              ) : null}
              <div>
                <h4>Back to the {backText}:</h4>
                <p>
                  <Link to={slug} title={title}>
                    {title}
                  </Link>
                </p>
              </div>
            </div>
            <div className="content">
              {showAuthorSummary ? <AuthorSummary author={author} /> : null}
            </div>
            {showComments ? (
              <div className="content">
                <DiscussionEmbed {...disqusConfig} />
              </div>
            ) : null}
          </div>
        </div>

        {footerImage ? (
          <FullWidthImage
            img={footerImage}
            subheading={footer}
            imgPosition="center"
            className="footer-image-text"
            showTitle={true}
          />
        ) : null}
      </section>
    </div>
  );
};

BlogGalleryPageTemplate.propTypes = {
  helmet: PropTypes.object,
  title: PropTypes.string.isRequired,
  name: PropTypes.string,
  subtitle: PropTypes.string,
  footer: PropTypes.string,
  images: PropTypes.shape({
    hero: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    smallImage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    smallImageUrl: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    footer: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  }),
  author: PropTypes.string,
  creationDate: PropTypes.string,
  sortDate: PropTypes.string,
  flags: PropTypes.shape({
    published: PropTypes.bool,
    featured: PropTypes.bool,
  }),
  options: PropTypes.shape({
    showTitle: PropTypes.bool,
    showSummary: PropTypes.bool,
    showTags: PropTypes.bool,
    showComments: PropTypes.bool,
    showAuthorSummary: PropTypes.bool,
    showPrevNext: PropTypes.bool,
    showPopular: PropTypes.bool,
    showRelated: PropTypes.bool,
  }),
  breadcrumbs: PropTypes.shape({
    parent: PropTypes.string,
    parentSlug: PropTypes.string,
    grandparent: PropTypes.string,
    grandparentSlug: PropTypes.string,
  }),
  body: PropTypes.string,
  summary: PropTypes.string,
};

const BlogGalleryPage = ({ data }) => {
  const { markdownRemark: post } = data;
  const { authors } = data;
  const { config } = data;
  let prefix;
  let imageSrc;

  if (
    post.frontmatter.images &&
    post.frontmatter.images.hero &&
    post.frontmatter.images.hero.childImageSharp
  ) {
    imageSrc =
      post.frontmatter.images.hero.childImageSharp.gatsbyImageData.images
        .fallback.src;
  }
  if (!post.frontmatter.titles) post.frontmatter.titles = {};

  if (post.fields.slug.indexOf("/blog") !== -1) {
    prefix = "Blog";
  } else {
    prefix = "Video";
  }
  if (!post.frontmatter.titles) post.frontmatter.titles = {};
  if (
    post.frontmatter.titles.subtitle === "" ||
    !post.frontmatter.titles.subtitle
  ) {
    post.frontmatter.titles.subtitle = "Photo Gallery";
  }
  return (
    <Layout>
      <BlogGalleryPageTemplate
        slug={post.fields.slug}
        title={post.frontmatter.title}
        subtitle={post.frontmatter.titles.subtitle}
        footer={post.frontmatter.titles.footer}
        images={post.frontmatter.images}
        options={post.frontmatter.options}
        breadcrumbs={post.frontmatter.breadcrumbs}
        body={post.html}
        tags={post.frontmatter.tags}
        summary={post.frontmatter.summary}
        category={post.frontmatter.category}
        helmet={
          <Seo
            title={`${prefix} > ${post.frontmatter.category} > ${post.frontmatter.title} > Photo Gallery`}
            keywords={`${post.frontmatter.category.toLowerCase()} photos, ${pluralize(
              post.frontmatter.category.toLowerCase(),
              1
            )} gallery, ${post.frontmatter.category.toLowerCase()} gallery`}
            summary={post.frontmatter.summary}
            image={imageSrc}
            article={false}
          />
        }
        authors={authors}
        author={post.frontmatter.author}
        config={config}
        photos={post.frontmatter.photos}
      />
    </Layout>
  );
};

BlogGalleryPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default BlogGalleryPage;

export const blogGalleryPageQuery = graphql`
  query BlogGalleryPage($parent: String!) {
    markdownRemark(fields: { slug: { eq: $parent } }) {
      html
      fields {
        slug
      }
      frontmatter {
        title
        titles {
          subtitle
          footer
        }
        tags
        images {
          hero {
            childImageSharp {
              gatsbyImageData(
                quality: 100
                layout: FULL_WIDTH
                placeholder: BLURRED
              )
            }
          }
          footer {
            childImageSharp {
              gatsbyImageData(
                quality: 100
                layout: FULL_WIDTH
                placeholder: BLURRED
              )
            }
          }
          smallImage {
            childImageSharp {
              gatsbyImageData(
                quality: 100
                layout: FULL_WIDTH
                placeholder: BLURRED
              )
            }
          }
          smallImageUrl
        }
        options {
          showTitle
          showSummary
          showTags
          showComments
          showAuthorSummary
          showPrevNext
          showTitlesSwapped
          showDisplayContentHeader
        }
        author
        creationDate
        summary
        category
        photos {
          showGallery
          photo {
            image {
              childImageSharp {
                thumb: gatsbyImageData(
                  width: 320
                  height: 240
                  placeholder: BLURRED
                )
                full: gatsbyImageData(layout: FULL_WIDTH)
              }
            }
            title
            summary
          }
        }
      }
    }
    authors: allMarkdownRemark(
      filter: {
        frontmatter: { advanced: { templateKey: { eq: "author-post" } } }
      }
    ) {
      nodes {
        id
        fields {
          slug
        }
        frontmatter {
          name
        }
      }
    }
    config: markdownRemark(
      frontmatter: {
        advanced: {
          configKey: { eq: "blog" }
          templateKey: { eq: "configuration" }
        }
      }
    ) {
      frontmatter {
        overrides {
          showTitle {
            enabled
            value
          }
          showSummary {
            enabled
            value
          }
          showTags {
            enabled
            value
          }
          showComments {
            enabled
            value
          }
          showAuthorSummary {
            enabled
            value
          }
          showPrevNext {
            enabled
            value
          }
        }
      }
    }
  }
`;
